html,
body,
body #root,
#root div.content {
  height: 100%;
  background-color: rgb(255, 255, 255);
}

#root > div {
  height: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.hover-underline:hover{
  text-decoration: underline;
  cursor: pointer;
}

.pointer{
  cursor: pointer;
}

.sideBar {
  height: 100%;
}

.nav-logo {
  height: 40px;
}

.nav-header-text {
  position:relative;
  display:table-cell;
  vertical-align:middle;
  width:190px;
  font-family: 'Raleway', sans-serif;
  color: #fff;
}

.nav-button {
  margin: 0 0 0 5px;
}

.content {
  padding-left: 60px;
  -webkit-transition:padding .30s ease;
  transition: padding 0.3s ease
}

.content.onexpand {
  padding-left: 250px;
}

.food-item {
  margin-bottom: 10px;
}

.breadcrumb-item {
  color: #1b71bd !important;
  cursor: pointer;
}

.breadcrumb-item.active {
  color: #333333 !important;
}

.product-img {
  width: 64px;
  height: 64px;
  overflow: hidden;
  object-fit: contain;
}

.construction {
  color: #fff;
  background-color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: auto;
}

.construction-body {
  text-align: center;
}

.construction-body p {
  margin: 0;
  color: #bbb;
}

.img-construction {
  width: 100px;
  margin-bottom: 20px;
}

/* LOADER */
.loader {
  position: fixed;
  display: inline-grid;
  top: 0;
  left: 0;
  text-align: center;
  align-content: center;
  height: 100%;
  width: 100%;
  background-color: #212121ef;
  z-index: 997;

  transition: all 0.25s ease;

}

.loader .logo {
  display: inline;
}

.loader span,
.loader strong {
  display: block
}

.jumbotron {
  padding: 1rem 0;
}
.display-3 {
  font-size: 3.2rem !important;
}

.row-color{
  background-color: #efefef !important;
}

.date_picker{
  width: 100%;
}
.date_picker_wremove{
  width: calc(100% - 35px);
}


.file_img_text{
  color:transparent;
}

@media (max-width: 500px) {
  .content {
    padding-left: 0;
    padding-top: 56px;
  }
  .content.onexpand {
    padding-left: 0;
  }
}